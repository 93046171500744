 /* Dashboard */

 .h4 {
   text-align: center;
   font-weight: bold;
   margin-bottom: 20;
 }

 .cardHutan {
   width: 100%;
   height: 250px;
   background-color: #054737;
   border: 2px solid white;
   border-radius: 4px;
 }

 .btnHutan {
   color: white !important;
 }

 .btnHutan:hover {
   opacity: 1.0 !important;
 }

 .imgHutan {
   width: 20%;
   height: 20%;
 }

 .container {
   position: relative;
   width: 100%;
   max-width: 100%;
   opacity: 0.7;
   max-height: 100%;
 }

 .container:hover {
   opacity: 2.0;
 }

 .container .btnHutan {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   background-color: Transparent;
   color: rgb(0, 0, 0);
   font-size: 19px;
   font-weight: bold;
   padding: 12px 24px;
   border: none;
   cursor: pointer;
   border-radius: 5px;
   text-align: center;
   opacity: 1.0;
 }

 /* Apps */

 .cardApps {
   height: 100%;
 }

 .containerApps {
   position: relative;
   width: 100%;
   max-width: 100%;
   opacity: 0.7;
   max-height: 100%;
   margin: auto;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   background-color: Transparent;
   color: rgb(0, 0, 0);
   font-size: 19px;
   font-weight: bold;
   padding: 12px 0px;
   border: none;
   cursor: pointer;
   border-radius: 5px;
   text-align: center;
   opacity: 1.0;
 }

 .containerApps:hover {
   opacity: 2.0;
   background-color: #054737;
   border-radius: 4px;
   color: honeydew;
 }

 .Icon {
   font-size: 50px !important;
 }

 /* Artikel Berita dan Video */

 .video {
   margin-top: 30px;
 }

 .h4AB {
   font-weight: bold;
 }

 .gambarArBe {
   max-height: 100px;
   max-width: 100px;
 }

 .chartAB {
   margin-top: -20px !important;
 }

 /* Footer */

 .search__input {
   margin-top: 10px;
 }

 .subscribe {
   height: 40px;
   margin-left: 3px !important;
 }

 /* .inputEmail{
width: 300px !important;
height: 40px !important;
 background-color: white;

 
} */

 .footerInner {
   margin-left: 90px !important;
 }

 /* Register */

 .registerBody {
   width: 70% !important;
   position: relative !important;
   margin: 0px 15%;
 }

 .cBodyRegister {
   margin-bottom: 20px;
   margin-top: 10px;
 }

 .spinnerMap{
  position: absolute; /*Can also be `fixed`*/
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  left: 0;
}

 .cBodyRegisterBot {
   margin-bottom: 20px;
 }

 .btnDaftar {
   width: 100% !important;
   background-color: #054737 !important;
   color: white !important;
   margin-bottom: 15px !important;
 }

 .btnKembali {
   width: 100% !important;
   background-color: white !important;
   color: black !important;
 }

 /* Login */

 .login {
   width: 50% !important;
   position: relative !important;
   margin: 0px 25%;
   margin-top: 30%px !important;
 }

 /* Informasi Program  & Survei Online*/
 /* .searchBox {
  width: 100%;
  margin-bottom: 10px;
  height: 36px;
  box-sizing: border-box;
  border: 2px solid #054737;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 10px;
  background-color: #054737;
  color: white !important;
  padding: 0px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
.paperSearch{
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 99%;
  height: 33px;
  border: 2px solid #054737;
  margin-top: 10px;
}
.iconButton{
  padding: 10;
}
.inputSearch{
  margin-left: 1px;
  flex: 1;
 
}
 .modal {
   /* display: flex; */
   align-items: center;
   justify-content: center;
   overflow-y: scroll;
 }

 .closeWindows {
   float: right;
   max-width: 20px;
   max-height: 20px;
   margin-top: 5px;
   cursor: pointer;
 }

 .dialogConText{
   width: 100%;}

   .marginInput{
     margin-bottom: 20px; 
     margin-top: 10px;
   }
 .gridcontainerBotom{
 margin-bottom: 30px !important;
 }
.widthInput{
 width: 100%;
}
.helperText{
color: red !important;
}
.buttonUpload {
  /* float: right; */
  background-color: rgb(11, 88, 204) !important;
  color: white !important;
}

.buttonDelete {
  /* float: right; */
  background-color: red !important;
  color: white !important;
  min-width: 85px;
}

.buttonEdit {
  /* float: right; */
  background-color: #054737 !important;
  color: white !important;
  margin-right: 15px !important;
  min-width: 85px;
}

.buttonConfirm {
  /* float: right; */
  background-color: #054737 !important;
  color: white !important;
}

.buttonClose {
  /* float: right; */
  background-color: white !important;
  color: black !important;
  margin-right: 20px !important;
}



::placeholder {
  color: white;
}
 .selectEmpty {
   margin-top: 0px;
   width: 100% !important
 }

 .paper {
   background-color: white;
   box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.8);
   padding: 10px;
   border-radius: 7px;
 }

 .containerDate {
   display: flex;
   flex-wrap: wrap;
 }

 .textFieldDate {
   /* marginLeft: theme.spacing(1);
    marginRight: theme.spacing(1); */
   width: 200;
 }

 .input {
   display: none;
 }
/* Artikel */
.judulArtikel{
  font-weight: 400;
}
.isiArtikel{
  max-height: 300px;
text-align: justify;
}
.pinggiranArtikel{
  max-height: 300px;
  
}
.judulPinggir{
  font-weight: 500;
  font-size: medium;
}

 @media only screen and (max-width: 576px) {
   /*Artikel  */
   .pinggiranArtikel{
     width: auto !important;
    
   }
   .judulPinggir{
    font-weight: 500;
    font-size: medium;
  }
   /* akhir Artikel */
  
  .registerBody {
     width: 100% !important;
     position: relative !important;
     margin: 0 !important;
     margin-top: 50px !important;
   }
   .login {
     width: 100% !important;
     position: relative !important;
     margin: 0 !important;
     margin-top: 50px !important;
   }
   .justify {
     justify-content: end;
   }
   .search__input {
     margin-top: 10px;
   }
   .subscribe {
     margin-left: 20px !important;
     margin-top: 5px !important;
   }
   .inputEmail {
     margin-left: 20px !important;
   }
   .footerInner {
     margin-left: 90px !important;
   }
   .video {
     width: 100%;
     height: 300px !important;
   }
   .gambarArBe {
     max-width: 100% !important;
     height: auto !important;
   }
   
 }

 .containerTitleHome{
   margin-bottom: 80px;
   font-size: 56px;
   font-weight: 500;
 }

 .containerResult{
padding-left: 10%;
padding-right: 10%;
 }

 .dataResultContainer{
  text-align: center;
  padding: 5;
  height:200px;
 }
 .dataResultText{
   font-size: 24px;
   font-weight: 400;
   padding:5;
   margin-bottom: 60px;
 }

 .dataResultValue{
  font-size: 50px;
  font-weight: 500;
  padding:5;
  margin-bottom: 20px;
 }

 .resultHeaderUrl{
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
}
 .resultHeaderDate{
   float:right;
   font-size: 16px;
   font-weight: 400;
 }
.dataResultTotalTitle{
display: inline-block;
font-weight: 400;
  font-size: 30px;
  padding: 10;
  margin-top: 70px;
  margin-bottom: 50px;
}
.dataResultTotalValue{
  float:right;
  font-weight: 500;
  font-size: 50px;
  padding: 10;
  margin-top: 70px;
  margin-bottom: 50px;
  }
 @media only screen and (max-width: 640px) {
   .containerResult{
     padding-left: 5px;
     padding-right: 5px;
   }
   .register {
     width: 100% !important;
     position: relative !important;
     margin: 0 !important;
     margin-top: 50px !important;
   }
   .login {
     width: 100% !important;
     position: relative !important;
     margin: 0 !important;
     margin-top: 50px !important;
   }
   .justify {
     justify-content: end;
   }
   .search__input {
     margin-top: 10px;
   }
   .subscribe {
     margin-left: 20px !important;
     /* margin-top: 5px !important; */
   }
   .inputEmail {
     margin-left: 20px !important;
   }
   .footerInner {
     margin-left: 90px !important;
   }
   .video {
     width: 100%;
     height: 300px !important;
   }
   .gambarArBe {
     max-width: 100% !important;
     height: auto !important;
   }
   .containerTitleHome{
    font-size: 26px !important;
    font-weight: 500 !important;
   }
   .searchUrl{
     width: 100% !important;
     margin-right: 0px;
     margin-bottom: 10px;
   }
   .btnUrl{
     height: 46px;
     width: 100% !important;
     background-color: #054737 !important;
     color: white !important;
     border-radius: 4px;
     border-style: solid;
   }


 }
 .btnUrl{
  height: 46px;
  width: 150px;
  background-color: #054737 !important;
  color: white !important;
  border-radius: 4px;
  border-style: solid;
}

 .successRegIcon{
  font-size: 100px;
  text-align: center;
  color: #054737;
  margin-bottom: 30px;
 }

 .deleteIcon{
  font-size: 100px;
  text-align: center;
  color: red;
  margin-bottom: 30px;
 }
 .searchUrl{
   margin-right: 10px;
   width:50%;
   height: 46px;
   padding-left:5 px !important;
   padding-right: 5px !important;

   border-style: solid;
   border-radius: 4px;
 }